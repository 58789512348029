@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html,
body,
#root,
.App {
    height: 100%;
}

.recharts-responsive-container {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}
