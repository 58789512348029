.project-selection-form-container {
    background-color: rgb(248, 249, 250);
    border-radius: 0.3em;
    padding: 15px;
    max-width: 768px;
    margin-right: 1rem;
    margin-left: 1rem;
    // box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;

    @include for-size(tablet) {
        margin: auto !important;
    }
}