.better-tooltip {
    // backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.85);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15);
}

.better-tooltip-header {
    margin-top: 5px;
    margin-bottom: 10px;
}

.better-tooltip-header-text {
    background: rgba(200, 200, 200, 0.5);
    border-radius: 5px;
    padding: 5px;
}

.better-tooltip-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.better-tooltip-amount {
    font-family: 'Roboto Mono', monospace;
    font-size: 13px;
}
