#invoice-trigger-activity-report-accordion {
    > div.title {
        font-size: 22px;
    }
    > div.content {
        > div.accordion {
            > div.title {
                font-size: 18px;
            }
        }
    }
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
    color: rgba(0, 0, 0, 0.8);
}
