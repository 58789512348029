.inverted-table-header {
    background-color: #313131 !important;
    color: #ffffff !important;
    border-left: 1px solid #656565 !important;
}

.gray-highlight-row {
    background-color: #f9fafb !important;
}

.light-blue-highlight-cell {
    background-color: #c8def1;
}

.light-orange-highlight-cell {
    background-color: #fce1c6;
}

.light-green-highlight-cell {
    background-color: #d4e7cd;
}

.total-footer-cell {
    border-top: 2px solid #a8a8a8 !important;
}