.ui.button.link {
  border: none;
  background: transparent;
  padding: 0;
  color: #ccc;
  text-decoration: underline;

  &:hover {
    color: #efefef;
  }
}
