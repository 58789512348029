.ui.selectable.table {
    tbody {
        tr {
            &:hover {
                td {
                    background-color: transparent;
                }
            }
        }
    }
}