@mixin for-size($size) {
    @if $size == tablet {
        @media (min-width: "768px") { @content; }
    } @else if $size == desktop {
        @media (min-width: "992px") { @content; }
    } @else if $size == desktop-lg {
        @media (min-width: "1400px") { @content; }
    } @else if $size == desktop-wide {
        @media (min-width: "1920px") { @content; }
    }
}