@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

html,body,#root,.App{height:100%}.recharts-responsive-container{font-family:Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important}

.react-datepicker-wrapper,.react-datepicker__input-container,.react-datepicker__input-container input{display:block;width:100%}div.react-datepicker{font-family:Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif}div.react-datepicker__triangle{border-bottom-color:#fff !important}div.react-datepicker__header{background-color:#fff;border-bottom:none}div.react-datepicker__header__dropdown{margin:5px 0}div.react-datepicker__day-names{background:#f5f5f5;margin:8px 0}div.react-datepicker__month-container{margin-bottom:3px}div.react-datepicker__today-button{background:#e0e1e2 none;color:rgba(0,0,0,0.6);border-top:none}div.react-datepicker__today-button:hover{background:#cacbcd;color:rgba(0,0,0,0.8)}div.react-datepicker__today-button:active{background:#babbbc;color:rgba(0,0,0,0.9)}div.react-datepicker__day--outside-month{visibility:hidden}

#project-creator-submit{pointer-events:auto !important}.ui.message.icon{display:flex !important}.client-input{flex-grow:1 !important}

.form-field{margin-bottom:0 !important}

.pfc-tooltip{background-color:rgba(255,255,255,0.85);padding:10px;border-radius:5px;box-shadow:0 1px 2px 0 rgba(34,36,38,0.15),0 0 0 1px rgba(34,36,38,0.15)}.pfc-tooltip-header{margin-top:5px;margin-bottom:10px}.pfc-tooltip-header-text{background:rgba(200,200,200,0.5);border-radius:5px;padding:5px}.tooltip-label{display:flex;flex-direction:row;justify-content:space-between}.pfc-tooltip-amount{font-family:'Roboto Mono', monospace;font-size:13px}

.contract-project-select{flex-grow:1 !important}

.view-options-box{max-width:250px;margin:20px 0 30px 0;padding:1rem;border:1px solid rgba(34,36,38,0.15);border-radius:0.28571429rem;box-shadow:0 1px 2px 0 rgba(34,36,38,0.15)}.view-options-box>div:not(:first-child){margin-top:15px}.view-options-checkbox-list .checkbox{padding:4px 0}.view-options-section-header{margin-bottom:2px}

.inverted-table-header{background-color:#313131 !important;color:#ffffff !important;border-left:1px solid #656565 !important}.gray-highlight-row{background-color:#f9fafb !important}.light-blue-highlight-cell{background-color:#c8def1}.light-orange-highlight-cell{background-color:#fce1c6}.light-green-highlight-cell{background-color:#d4e7cd}.total-footer-cell{border-top:2px solid #a8a8a8 !important}

.better-tooltip{background-color:rgba(255,255,255,0.85);padding:10px;border-radius:5px;box-shadow:0 1px 2px 0 rgba(34,36,38,0.15),0 0 0 1px rgba(34,36,38,0.15)}.better-tooltip-header{margin-top:5px;margin-bottom:10px}.better-tooltip-header-text{background:rgba(200,200,200,0.5);border-radius:5px;padding:5px}.better-tooltip-label{display:flex;flex-direction:row;justify-content:space-between}.better-tooltip-amount{font-family:'Roboto Mono', monospace;font-size:13px}

.view-options-box{max-width:250px;margin:20px 0 30px 0;padding:1rem;border:1px solid rgba(34,36,38,0.15);border-radius:0.28571429rem;box-shadow:0 1px 2px 0 rgba(34,36,38,0.15)}.view-options-box>div:not(:first-child){margin-top:15px}.view-options-checkbox-list .checkbox{padding:4px 0}.view-options-section-header{margin-bottom:2px}

.slider-container{width:100%;display:flex;flex-direction:row;align-items:center;margin-top:8px;margin-bottom:8px}.slider{-webkit-appearance:none;appearance:none;width:100%;height:0.4em;background:#f2f2f2;border-radius:4px;transition:opacity 0.2s}.slider:hover{opacity:1}.slider:disabled{background:#ccc !important}.slider:disabled::-webkit-slider-thumb:hover{background:#fff linear-gradient(transparent, rgba(0,0,0,0.05));cursor:not-allowed}.slider::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;background:#fff linear-gradient(transparent, rgba(0,0,0,0.05));box-shadow:0 1px 2px 0 rgba(34,36,38,0.15),0 0 0 1px rgba(34,36,38,0.15) inset;width:1.5em;height:1.5em;border-radius:100%}.slider::-webkit-slider-thumb:hover{background:#f2f2f2 linear-gradient(transparent, rgba(0,0,0,0.05))}

#invoice-trigger-activity-report-accordion>div.title{font-size:22px}#invoice-trigger-activity-report-accordion>div.content>div.accordion>div.title{font-size:18px}.ui.styled.accordion .accordion .title,.ui.styled.accordion .title{color:rgba(0,0,0,0.8)}

.better-tooltip{background-color:rgba(255,255,255,0.85);padding:10px;border-radius:5px;box-shadow:0 1px 2px 0 rgba(34,36,38,0.15),0 0 0 1px rgba(34,36,38,0.15)}.better-tooltip-header{margin-top:5px;margin-bottom:10px}.better-tooltip-header-text{background:rgba(200,200,200,0.5);border-radius:5px;padding:5px}.better-tooltip-label{display:flex;flex-direction:row;justify-content:space-between}.better-tooltip-amount{font-family:'Roboto Mono', monospace;font-size:13px}

.date-slider-container{width:100%;display:flex;flex-direction:row;align-items:center;margin-top:8px;margin-bottom:8px}.date-slider{-webkit-appearance:none;appearance:none;width:100%;height:0.4em;background:#f2f2f2;border-radius:4px;transition:opacity 0.2s}.date-slider:hover{opacity:1}.date-slider:disabled{background:#ccc !important}.date-slider:disabled::-webkit-slider-thumb:hover{background:#fff linear-gradient(transparent, rgba(0,0,0,0.05));cursor:not-allowed}.date-slider::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;background:#fff linear-gradient(transparent, rgba(0,0,0,0.05));box-shadow:0 1px 2px 0 rgba(34,36,38,0.15),0 0 0 1px rgba(34,36,38,0.15) inset;width:1.5em;height:1.5em;border-radius:100%}.date-slider::-webkit-slider-thumb:hover{background:#f2f2f2 linear-gradient(transparent, rgba(0,0,0,0.05))}

#instat-logo{margin-left:-20px}.auth-page{height:100%;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;color:#fff;background-image:url(/static/media/login-background.43895d83.jpeg);background-size:cover;z-index:0}.auth-page-text{display:flex;flex-direction:column;align-items:center;max-width:350px;text-align:center;margin-top:25px;margin-bottom:50px}.auth-page-text h1{font-size:3em}.auth-page-text p{margin:0 10px}

.ui.button.link{border:none;background:transparent;padding:0;color:#ccc;text-decoration:underline}.ui.button.link:hover{color:#efefef}

.project-selection-form-container{background-color:#f8f9fa;border-radius:0.3em;padding:15px;max-width:768px;margin-right:1rem;margin-left:1rem}@media (min-width: 768px){.project-selection-form-container{margin:auto !important}}.cost-analysis-report-table-placeholder{height:400px !important;max-width:768px !important;margin-top:10px !important;margin-left:1rem !important;margin-right:1rem !important;border:none !important;box-shadow:none !important}@media (min-width: 768px){.cost-analysis-report-table-placeholder{margin:auto !important;margin-top:10px !important}}.ui.selectable.table tbody tr:hover td{background-color:transparent}.home-card-group{background-color:#f9fafb;border-radius:0.3em;margin-top:15px;margin-bottom:15px;padding:30px 21px 10px 21px}.active-projects-report-menu{background-color:#f8f9fa;border-radius:0.3em;padding:15px;max-width:768px;margin-right:1rem;margin-left:1rem;margin-bottom:20px !important}@media (min-width: 768px){.active-projects-report-menu{margin:auto !important;margin-bottom:20px !important}}

