.cost-analysis-report-table-placeholder {
    height: 400px !important;
    max-width: 768px !important;
    margin-top: 10px !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border: none !important;
    box-shadow: none !important;
    // box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;

    @include for-size(tablet) {
        margin: auto !important;
        margin-top: 10px !important;
    }
}