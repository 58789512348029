.view-options-box {
    max-width: 250px;
    margin: 20px 0 30px 0;
    padding: 1rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);

    > div:not(:first-child) {
        margin-top: 15px;
    }
}

.view-options-checkbox-list {
    .checkbox {
        padding: 4px 0;
    }
}

.view-options-section-header {
    margin-bottom: 2px;
}
